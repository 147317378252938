import { ILogger, logger } from '@fiedler/frontend-logger';

export class LocaleService {
    @logger('locale', 'locale service')
    private static readonly logger: ILogger;
    private static readonly DEFAULT_LOCALE: string = 'en';
    public static getLocale(): string {
        try {
            const url: URL = new URL(window.location.href);
            let language: string | null = url.searchParams.get('locale');
            if (language) {
                LocaleService.logger.debug('locale loaded from query param {}', language);
                return language;
            }
            try {
                language = localStorage.getItem('locale');
                if (language) {
                    LocaleService.logger.debug('locale loaded from local storage {}', language);
                    return language;
                }
            } catch (error) {
                LocaleService.logger.warn('Failed to load locale from local storage.');
            }
            language = navigator.language.slice(0, 2);
            LocaleService.logger.debug('locale loaded from navigator {}', language);
            return language;
        } catch (error) {
            LocaleService.logger.warn('failed to load locale - default locale is {}', LocaleService.DEFAULT_LOCALE, error);
            return LocaleService.DEFAULT_LOCALE;
        }
    }
}
