import { autoinject } from 'aurelia-framework';
import { TValueConverter } from 'aurelia-i18n';
import { Router, RouterConfiguration } from 'aurelia-router';

import { RouterConfigurationBuilder } from '@fiedler/aurelia-login';

import * as Notifications from '../../../static/files/notifications.json';

type TSchedule = typeof Notifications.schedule[0];

@autoinject()
export class MainRoute {
    protected message: string = '';
    protected color: string = '#006cbf';
    protected fontColor: string = 'white';
    public router!: Router;

    public constructor(private readonly t: TValueConverter) {}

    public async attached(): Promise<void> {
        let language: string = navigator.language.split('-')[0];
        if (language !== 'en' && language !== 'cs' && language !== 'de') {
            language = 'default';
        }
        const timestamp: number = Math.floor(Date.now() / 1000);
        const notification: TSchedule | undefined = Notifications.schedule.find((schedule: TSchedule): boolean => {
            return schedule.from <= timestamp && timestamp < schedule.to;
        });
        if (notification) {
            this.message = Notifications.messages[notification.message].languages[language];
            this.color = Notifications.messages[notification.message].color;
            this.fontColor = Notifications.messages[notification.message].fontColor;
        }
    }

    public configureRouter(config: RouterConfiguration, router: Router): void {
        router.transformTitle = (key: string): string => {
            return this.t.toView(key);
        };
        config.options.root = '/';
        config.options.pushState = true;
        /* RouterConfigurationBuilder.addRoutes(config);
        RouterConfigurationBuilder.addDefaultRoute(config);
        RouterConfigurationBuilder.addBasicFlow(config);
        RouterConfigurationBuilder.addRedirectFlow(config); */
        new RouterConfigurationBuilder(<RouterConfiguration>config).addBasicFlow().addRedirectFlow().build();
        this.router = router;
    }
}
