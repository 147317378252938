import * as Sentry from '@sentry/browser';
import { BrowserTracing } from '@sentry/tracing';
import { Aurelia, LogManager } from 'aurelia-framework';
import { AureliaEnhancedI18Next, I18N, TCustomAttribute } from 'aurelia-i18n';
import { PLATFORM } from 'aurelia-pal';
import Backend from 'i18next-xhr-backend';

import { ETranslationFiles } from '@fiedler/aurelia-login';
import { AureliaAppender } from '@fiedler/frontend-aurelia-logger';
import {
    Configuration as LoggerConfiguration,
    createSentryOutput,
    EOutputMode,
    ICustomOutputConfiguration,
    LoggerFactory
} from '@fiedler/frontend-logger';

import configuration from '../../../configuration/configuration.json';
import { LocaleService } from '../../common';
import environment from '../../environment';
import { Configuration } from '../../modules';

import 'core-js/stable';

import Icons from 'uikit/dist/js/uikit-icons';
import UIkit from 'uikit';

const sentryConfiguration: Sentry.BrowserOptions = {
    enabled: configuration.logs.sentry.enabled,
    dsn: configuration.logs.sentry.dsn,
    tunnel: `/sentry/api/3/envelope/?sentry_key=${configuration.logs.sentry.publicKey}`,
    integrations: [new BrowserTracing()],
    debug: configuration.logs.sentry.debug,
    environment: configuration.logs.sentry.environment,
    tracesSampleRate: configuration.logs.sentry.tracesSampleRate
};

export async function configure(aurelia: Aurelia): Promise<void> {
    Sentry.init(sentryConfiguration);
    const loggerOutput: ICustomOutputConfiguration = {
        mode: EOutputMode.CUSTOM,
        log: createSentryOutput(Sentry.captureException)
    };

    LoggerFactory.configure(
        new LoggerConfiguration({
            level: configuration.logs.level,
            output: loggerOutput
        })
    );

    LogManager.setLevel(LogManager.logLevel.debug);
    LogManager.addAppender(new AureliaAppender(LoggerFactory.create));
    Configuration.register(aurelia.container, configuration);

    UIkit.use(Icons);

    aurelia.use.standardConfiguration();
    if (environment.testing) {
        aurelia.use.plugin(PLATFORM.moduleName('aurelia-testing'));
    }
    aurelia.use.plugin(PLATFORM.moduleName('aurelia-i18n'), async (instance: I18N): Promise<AureliaEnhancedI18Next> => {
        const aliases: string[] = ['t', 'i18n'];
        // add aliases for 't' attribute
        TCustomAttribute.configureAliases(aliases);
        // register backend plugin
        instance.i18next.use(Backend);
        return instance.setup({
            attributes: aliases,
            backend: {
                loadPath: (language: string, namespace: string): string => {
                    return `${configuration.api.url.translations}/${namespace}/${language}.json`;
                }
            },
            debug: configuration.logs.i18nextDebug,
            fallbackLng: 'en',
            lng: LocaleService.getLocale(),
            ns: [ETranslationFiles.LOGIN, ETranslationFiles.DICTIONARY],
            interpolation: {
                format: (value: any, format?: string, lng?: string): string => {
                    if (format === 'date') {
                        return Intl.DateTimeFormat(lng, {
                            timeStyle: 'medium',
                            dateStyle: 'short'
                        }).format(new Date(value));
                    }
                    return value;
                }
            }
        });
    });

    await aurelia.start();
    await aurelia.setRoot(PLATFORM.moduleName('applications/main/main.route'));
}
