import { Container } from 'aurelia-dependency-injection';

import { IApiConfiguration } from './api-configuration.interface';
import { ICdnConfiguration } from './cdn-configuration.interface';
import { IConfiguration } from './configuration.interface';
import { ILogsConfiguration } from './logs-configuration.interface';

export class Configuration implements IConfiguration {
    public readonly api: IApiConfiguration;
    public readonly cdn: ICdnConfiguration;
    public readonly logs: ILogsConfiguration;
    public constructor(config: IConfiguration) {
        this.api = config.api;
        this.cdn = config.cdn;
        this.logs = config.logs;
    }

    public static register(container: Container, configuration: IConfiguration): void {
        container.registerInstance(Configuration, new Configuration(configuration));
    }
}
